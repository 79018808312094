import React from 'react'
import styled from 'styled-components'

const CancelRegistrationPage = () => {
  return (
    <StyledCancelRegistrationPage>
      <h1>Registration Payment Canceled</h1>
      <p>Your registration payment has been canceled.</p>
      <p>We hope to engage with you further on your studies with UniCollege.</p>
    </StyledCancelRegistrationPage>
  )
}

const StyledCancelRegistrationPage = styled.main`
  max-width: 1500px;
  margin: auto;
  padding: 6rem 2rem;

  h1 {
    margin-bottom: 2rem;
  }

  p {
    margin-bottom: 1rem;
  }

  @media (max-width: 480px) {
    padding: 2rem 1rem;
  }
`

export default CancelRegistrationPage
